import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { format, differenceInDays } from 'date-fns';
import { createNewTask } from '../../api/create-task';
import { useAuth } from "../../contexts/AuthContext";
import { Loading } from '../../components/Loading';
import {
  BrandingWrapper,
  Profile,
  Container,
  Title,
  Form,
  TitleForm,
  Content,
  Select,
  ButtonForm,
  ContentModal,
  Footer,
  VacationDates,
  VacationSwitch,
  VacationSwitchWrapper,
  InfoContainer,
  CollaboratorContainer
} from "./style";
import {
  TmpHeaderBase,
  DropdownProfile,
  TmpInput,
  TmpSelect,
  TmpSwitch,
  TmpTextarea,
  TmpButton,
  TmpModal,
} from "@tempo/tempo-design-system-core/dist";
import { searchContributor } from '../../api/search-contributor';
import LogOut from "@tempo/tempo-assets/dist/react/icons/sair";
import { AnimationIcon } from '../../components/AnimationIcon';
import { Uploader } from '../../components/Uploader';
import { DateRange } from '../../components/DateRange';
import { InfoButton } from '../../components/InfoButton';
import successAnimation from '../../assets/animations/success.json';
import errorAnimation from '../../assets/animations/error.json';
import LogoRAVI from "../../assets/logo_ravi.svg";
import CloseButton from "../../assets/close_button.svg";
import WarningIcon from "../../assets/warning_icon.svg";
import { INFO_BUTTON_TEXT } from './constants';
import axios from 'axios';

const RhForm = () => {
  const navigate = useNavigate();

  const { handleLogOut, user } = useAuth();

  const [values, setValues] = useState({ registration: '' });
  const [wage, setWage] = useState({ allowance: false, tenth: false });
  const [isOpened, setIsOpened] = useState(false);
  const [isOpenedLogOut, setIsOpenedLogOut] = useState(false);

  const [errorMessage, setErrorMessage] = useState({
    unit: "",
    reason: "",
    subreason: "",
    registration: "",
  });

  const [error, setError] = useState({
    unit: false,
    reason: false,
    subreason: false,
    registration: false,
  });

  const [integrationError, setIntegrationError] = useState('');

  const [files, setFiles] = useState([]);
  const [selectSubreason, setSelectSubreason] = useState(true);
  const [listItems, setListItems] = useState([{ label: "Nenhum" }]);
  const [requestMessage, setRequestMessage] = useState('Processando...');
  const [requestStatus, setRequestStatus] = useState('processing');
  const [contributor, setContributor] = useState(null);
  const [contributorError, setContributorError] = useState('');
  const [findAttempts, setFindAttempts] = useState(0);
  const [hasError, setHasError] = useState(null);

  const isException = values.subreason === 'Solicitação de programação de férias (Exceção fora do prazo)'
    || values.subreason === 'Solicitação de exclusão de férias (Exceção fora do prazo)';

  const dateRequired = values.subreason === 'Solicitação de programação de férias (Exceção fora do prazo)'
    || values.subreason === 'Solicitação de exclusão de férias (Exceção fora do prazo)';

  const isDevolution = values.subreason === 'Pagamentos e devoluções';

  const isWaiverLetter = values.subreason === 'Processo de rescisão';

  const username = user.nome;

  const devolutionList = [
    { label: 'Devoluções de faltas injustificadas' },
    { label: 'Devoluções de descontos em horas' },
    { label: 'Devoluções de encargos (INSS e IRRF )' },
    { label: 'Insalubridade' },
    { label: 'Periculosidade' },
  ];

  const hslUnits = [
    {
      label: "BELA VISTA",
    },
    {
      label: "BRASILIA",
    },
    {
      label: "UNIDADE ITAIM",
    },
    {
      label: "UNIDADE JARDINS",
    },
    {
      label: "UNIDADE SAÚDE POP",
    },
    {
      label: "UNIDADE SAÚDE 2",
    },
  ];

  const hslReasons = [
    {
      label: "Pagamentos e devoluções",
      chamadoName: "salariosEPagamentos",
    },
    {
      label: "Licenças e Folgas",
      chamadoName: "licencasFolgas",
    },
    {
      label: "Benefícios",
      chamadoName: "beneficios",
    },
    {
      label: "Férias",
      chamadoName: "ferias",
    },
    {
      label: "Controle de Ponto",
      chamadoName: "controleDePonto",
    },
    {
      label: "Rescisão",
      chamadoName: "rescisao",
    },
    {
      label: "Solicitações Adicionais",
      chamadoName: "solicitacoesAdicionais",
    },
    {
      label:
        "Desenvolvimento Organizacional / Cursos e Treinamentos",
      chamadoName:
        "desenvolvimentoOrganizacionalCursosTreinamentos",
    },
    {
      label: "Remuneração",
      chamadoName: "remuneracao",
    },
    {
      label: "Movimentações",
      chamadoName: "movimentacoes",
    },
    {
      label: "Medicina do Trabalho",
      chamadoName: "medicinadotrabalho",
    },
    {
      label: "Serviço Social",
      chamadoName: "servicosocial",
    }
  ].sort((a, b) => a.label.localeCompare(b.label));

  const motivosChamados = {
    salariosEPagamentos: [
      "Data de pagamento do salário",
      "Datas de pagamento do 13o salário",
      "Pagamentos e devoluções",
      "Prazo de entrega da carta de proporcionalidade de INSS",
      "Prévia/Divergência do demonstrativo de pagamento",
      "Empréstimo consignado em folha",
      "Sobreaviso",
      "Acionamento Sobreaviso",
    ],
    licencasFolgas: [
      "🍼 Licença Amamentação",
      "👪 Adoção de Crianças",
      "👶 Licença Maternidade",
      "👼 Licença Paternidade",
      "💑 Licença Gala (casamento)",
      "😇 Doação de Sangue",
      "😭 Licença Falecimento",
      "⚕️ Folga Enfermagem",
      "🔋 Recarrega",
    ],
    beneficios: [
      "Vale Transporte",
      "Vale Alimentação",
      "Vale Refeição",
      "Univers",
      "Assistência Médica (MediService)",
      "OdontoPrev",
      "Auxílio Complementar de Incapacidade Temporária - ACIT",
      "Seguro De Vida",
      "Gympass",
      "Creche",
      "Auxílio/Checkin Home Office",
    ],
    ferias: [
      "Solicitação de programação de férias (Exceção fora do prazo)",
      "Solicitação de exclusão de férias (Exceção fora do prazo)",
      "Recibo e pagamento de férias",
      "Empréstimo consignado descontado em férias",
      "Recibo de férias para assinatura",
      "Valor a receber e data para pagamento",
      "Fracionamento de férias",
      "Programação de férias",
      "Dúvidas no holerite em relação as férias",
      "Empréstimo consignado descontado duas vezes",
    ],
    controleDePonto: [
      "Adicional noturno",
      "Ajuste de escala",
      "Banco de Horas",
      "Gestor do ponto",
      "Refeitório",
      "Liberação refeitorio",
      "Auxílio/Checkin Home Office",
    ],
    rescisao: [
      "Pagamento",
      "Processo de rescisão",
      "Consulta de estabilidade",
    ],
    solicitacoesAdicionais: [
      "Solicitações Adicionais (PPP, RDT e atualização de CTPS)",
      "Solicitações de Contrato de Trabalho (Experiência, contrato determinado e etc)",
      "Advertência",
      "Suspensão",
    ],
    desenvolvimentoOrganizacionalCursosTreinamentos: [
      "Competências Institucionais",
      "Ciclo de Desempenho",
      "Comunidade Feedback",
      "Capacitações",
    ],
    remuneracao: [
      'Análise de Estrutura',
      'Méritos',
      'Retenções',
      'Criação / Atualização Descrição de Cargo',
      'Análise CBO Cargo',
      'Problemas com Aceite Descrição de Cargo',
      'Gratificações Projetos',
      'Programa Compartilhar',
    ],
    movimentacoes: [
      'Movimentações - Exceções',
      'Movimentações - Análise de elegibilidade',
      'Movimentações - Dúvidas',
      'Ajuste de estrutura - Solicitações',
      'Posição - Solicitações',
      'Posição - Dúvida',
      'Requisição - Dúvidas',
    ],
    medicinadotrabalho: [
      'Abono de Atestado',
    ],
    servicosocial: [
      'Afastamento Colaborador',
    ],
  };

  const objectiveItems = [
    { label: 'Nova Estrutura' },
    { label: 'Revisão Estrutura' },
  ];

  const waiverLetterTypes = [
    { label: 'Envio de carta de dispensa (fora do prazo)' }
  ];

  const additionalTypes = [
    { label: 'Solicitação de pagamento' },
    { label: 'Dúvida' },
  ];

  const solicitationTypes = [
    { label: 'Solicitação de pagamento' },
    { label: 'Dúvidas' },
  ];

  const getInitials = (name) => {
    if (name && name?.split(" ")?.length > 0) {
      return name
        ?.split(" ")
        ?.map((value) => value?.charAt(0))
        ?.join("")
        ?.substr(0, 2)
        ?.toUpperCase();
    }
    return name;
  };

  function handleClose() {
    navigate("/lista");
  }

  async function handleValueChange(e) {
    setValues({ ...values, [e.target.id]: e.target.value });

    if (e.target.id === "registration") {
      const copyErrorMessage = errorMessage;
      delete copyErrorMessage.registration;
      setErrorMessage({
        ...copyErrorMessage,
      });
      const copyError = error;
      copyError.registration = false;
      setError(copyError);
      setValues({ ...values, registration: e.target.value.replace(/\s+/g, '') });

      setFindAttempts(0);
      setContributor(null);
    }
  }

  async function handlerFindCollaborator() {
    setContributor(null);
    setContributorError('');
    const existsContributor = await searchContributor(values.registration);

    if (!existsContributor) {
      setContributorError('Colaborador não encontrado');
      return;
    }

    setContributor(existsContributor);
    setContributorError('');
  }

  function getReason(e, name) {
    setValues({ ...values, [name]: e.label });
    setSelectSubreason(false);
    const listaSubmotivos = motivosChamados[e.chamadoName].map((submotivo) => {
      return {
        label: submotivo,
      };
    });
    setListItems(listaSubmotivos);
  }

  function handleSelectChange(e, name) {
    setErrorMessage({})

    setValues({ ...values, [name]: e.label });

    if (name === "unit") {
      const copyErrorMessage = errorMessage;
      delete copyErrorMessage.unit;
      setErrorMessage({
        ...copyErrorMessage,
      });
      const copyError = error;
      copyError.unit = false;
      setError(copyError);
    }

    if (name === "reason") {
      getReason(e, name);
      const copyErrorMessage = errorMessage;
      delete copyErrorMessage.reason;
      setErrorMessage({
        ...copyErrorMessage,
      });
      const copyError = error;
      copyError.reason = false;
      setError(copyError);
    }

    if (name === "subreason") {
      const copyErrorMessage = errorMessage;
      delete copyErrorMessage.subreason;
      setErrorMessage({
        ...copyErrorMessage,
      });
      const copyError = error;
      copyError.subreason = false;
      setError(copyError);
    }

    if (name === 'devolution') {
      const copyErrorMessage = errorMessage;
      delete copyErrorMessage.devolution;
      setErrorMessage({
        ...copyErrorMessage,
      });
      const copyError = error;
      copyError.devolution = false;
      setError(copyError);
    }
  }

  function handleCheckbox(name) {
    if (name === 'allowance') {
      setWage({ ...wage, allowance: !wage.allowance });
    }

    if (name === 'adiantar_13') {
      setWage({ ...wage, tenth: !wage.tenth });
    }
  }

  function handleUploadFiles(files) {
    setFiles(files);
  }

  async function handleSubmit(e) {
    e.preventDefault();

    let errorMessages = {}

    if (!values.unit) {
      errorMessages = {
        ...errorMessage,
        ...{ unit: "Campo unidade obrigatório." },
      }
    } else if (!values.reason) {
      errorMessages = {
        ...errorMessage,
        ...{ reason: "Campo reason obrigatório." },
      }
    } else if (!values.subreason) {
      errorMessages = {
        ...errorMessage,
        ...{ subreason: 'Campo SubMotivo é obrigatório' }
      }
    } else if (!values.registration) {
      errorMessages = {
        ...errorMessage,
        ...{ registration: "Campo registration obrigatório." },
      }
    } else if (dateRequired && !values.ferias) {
      errorMessages = {
        ...errorMessages,
        ...{ dataRequired: "Campo período de férias obrigatório." },
      }
    }

    setErrorMessage({ ...errorMessage, ...errorMessages })

    if (!contributor) {
      setContributorError('Busque um colaborador válido');
      return;
    }

    if (
      values.unit &&
      values.reason &&
      values.subreason &&
      values.registration &&
      Object.keys(errorMessages).length === 0
    ) {
      try {
        setRequestStatus('processing');
        setIsOpened(true);

        await createNewTask({
          unidade: values.unit,
          motivo: values.reason,
          submotivo: values.subreason,
          matriculas_colaboradores: values.registration,
          ...(files.length > 0 && {
            anexo: files,
          }),
          ...(values.ferias && {
            ferias: values.ferias,
          }),
          ...(values.description && {
            descricao: values.description,
          }),
          ...(wage.allowance && {
            abono: wage.allowance,
          }),
          ...(wage.tenth && {
            adiantar_13: wage.tenth,
          }),
          ...(values.devolution && {
            devolution: values.devolution,
          }),
          ...(values.diretoria && {
            diretoria: values.diretoria,
          }),
          ...(values.objetivo && {
            objetivo: values.objetivo,
          }),
          ...(values.waiverLetterType && {
            waiverLetterType: values.waiverLetterType,
          }),
          ...(values.additionalType && {
            additionalType: values.additionalType,
          }),
          ...(values.tipoSobreaviso && {
            tipoSobreaviso: values.tipoSobreaviso,
          }),
          ...(user.accountId && {
            accountId: user.accountId,
          }),
          email: user.email,
        });

        setRequestStatus('success');
        setRequestMessage('Cadastrado com sucesso !');
      } catch (error) {
        setRequestMessage('Ops, algo deu errado !');
        setRequestStatus('error');

        if (axios.isAxiosError(error)) {
          if (error.response.status === 400) {
            setIntegrationError('Erro na integração com o Salesforce');
          }

          if (error.response.status === 413) {
            setIntegrationError('Arquivo anexado muito grande');
          }

          if (error.response.status === 500) {
            setIntegrationError('Erro interno no servidor');
          }
        }
      } finally {
        setTimeout(() => {
          setIsOpened(false);
          navigate('/lista');
        }, 4000);
      }
    }
  }

  const handleVacationDate = ({ startDate, endDate }) => {
    setErrorMessage({})

    const dateDiff = differenceInDays(endDate, startDate)

    if (dateDiff < 5) {
      setErrorMessage({
        ...errorMessage,
        diffDate: 'Selecione um período mínimo de 5 dias',
      })
      return
    }

    setValues({ ...values, ferias: {
      inicio: format(startDate, 'dd/MM/yyyy'),
      final: format(endDate, 'dd/MM/yyyy'),
    }});
  }

  function ano() {
    return new Date().getFullYear();
  }

  return (
    <Container>
      <TmpHeaderBase
        children={
          <BrandingWrapper>
            <img src={LogoRAVI} alt="Logo RAVI"></img>
            <h1>RAVI - Chamados RH - Gestor</h1>
            <Profile>
              <Profile>
                <DropdownProfile
                  name={user.nome}
                  account="Conta principal"
                  avatarInitials={getInitials(username)}
                  listItems={[
                    {
                      label: "Sair da minha conta",
                      leadingIcon: <LogOut />,
                      handleClick: () => setIsOpenedLogOut(true),
                    },
                  ]}
                ></DropdownProfile>
                <TmpModal
                  isOpened={isOpenedLogOut}
                  isDoubleAction={true}
                  labelPrimary="Sim"
                  labelSecondary="Não"
                  handleConfirm={(e) => handleLogOut()}
                  handleCancel={(e) => setIsOpenedLogOut(false)}
                  handleClose={(e) => setIsOpenedLogOut(false)}
                >
                  <ContentModal>
                    <img src={WarningIcon} alt="Ícone de aviso" />
                    <p>Deseja sair da sua conta?</p>
                  </ContentModal>
                </TmpModal>
              </Profile>
            </Profile>
          </BrandingWrapper>
        }
      />
      <Title>
        <h1>Nova Manifestação</h1>
        <button id="button-close" onClick={handleClose}>
          <img src={CloseButton} alt="Fechar" />
        </button>
      </Title>
      <Form>
        <TitleForm>
          <h2>Contato</h2>
        </TitleForm>
        <Content>
          <TmpInput
            disabled={!!user.nome}
            id="name"
            handleChange={handleValueChange}
            label="Nome"
            placeholder={user.nome}
            maxLength={50}
          />

          <TmpInput
            disabled={!!user.email}
            id="email"
            handleChange={handleValueChange}
            label="E-mail"
            placeholder={user.email}
            maxLength={50}
          />

          <Select>
            <TmpSelect
              error={!!errorMessage.unit}
              handleChange={(e) => handleSelectChange(e, "unit")}
              label="*Unidade"
              placeholder="Selecione a unidade"
              helperText={errorMessage.unit ? errorMessage.unit : ""}
              listItems={hslUnits}
            />

            <TmpSelect
              error={!!errorMessage.reason}
              handleChange={(e) => handleSelectChange(e, "reason")}
              label="*Motivo Chamado"
              placeholder="Selecione o motivo"
              helperText={errorMessage.reason ? errorMessage.reason : ""}
              listItems={hslReasons}
            />

            <TmpSelect
              disabled={selectSubreason}
              error={!!errorMessage.subreason}
              handleChange={(e) => handleSelectChange(e, "subreason")}
              label="*Submotivo Chamado"
              placeholder="Selecione o submotivo"
              helperText={error.subreason ? errorMessage.subreason : ''}
              listItems={listItems}
            />

            {values.reason === 'movimentacoes' && (
              <TmpSelect
                handleChange={(e) => handleSelectChange(e, "movimentacoes")}
                label="*Tipo Devolução"
                placeholder="Selecione o tipo de pagamento/devolução"
                listItems={devolutionList}
              />
            )}

            {values.subreason === 'Adicional noturno' && (
              <TmpSelect
                handleChange={(e) => handleSelectChange(e, "additionalType")}
                label="*Tipo"
                placeholder="Selecione o tipo de adicional"
                listItems={additionalTypes}
              />
            )}

            {values.reason === 'Remuneração' && (
              <>
                <TmpInput
                  id="diretoria"
                  handleChange={handleValueChange}
                  label="*Diretoria Solicitante"
                />

                <TmpSelect
                  id="objetivo"
                  label="*Objetivo"
                  handleChange={(e) => handleSelectChange(e, "objective")}
                  placeholder="Selecione o Objetivo"
                  listItems={objectiveItems}
                />
              </>
            )}

            {(values.subreason === 'Sobreaviso' || values.subreason === 'Acionamento Sobreaviso') && (
              <TmpSelect
                handleChange={(e) => handleSelectChange(e, "tipoSobreaviso")}
                label="*Tipo Solicitação"
                placeholder="Selecione o tipo de solicitação"
                listItems={solicitationTypes}
              />
            )}

            {isDevolution && (
              <TmpSelect
                handleChange={(e) => handleSelectChange(e, "devolution")}
                label="*Tipo Devolução"
                placeholder="Selecione o tipo de devolução"
                listItems={devolutionList}
              />
            )}
          </Select>

          {isException && (
            <>
              <VacationDates>
                <DateRange
                  label={'*Período de Férias'}
                  onConfirm={handleVacationDate}
                  error={errorMessage.dataRequired || errorMessage.diffDate}
                />

                <VacationSwitchWrapper>
                  <VacationSwitch>
                    <TmpSwitch
                      id="abono"
                      label="Abono"
                      name="checkboxTwo"
                      checked={wage.allowance}
                      handleChange={() => handleCheckbox('allowance')}
                    />

                    <TmpSwitch
                      id="adiantar_13"
                      label="Adiantar 13º"
                      name="checkbox"
                      checked={wage.tenth}
                      handleChange={() => handleCheckbox('adiantar_13')}
                    />
                  </VacationSwitch>

                  <small>
                    <b>Abono{' '}</b>
                    {'(Para solicitações de 20 dias de descanso + 10 dias de abono) '}
                  </small>
                </VacationSwitchWrapper>
              </VacationDates>
            </>
          )}

          {isWaiverLetter && (
            <TmpSelect
              handleChange={(e) => handleSelectChange(e, "waiverLetterType")}
              label="*Tipo Rescisão"
              placeholder="Selecione o tipo de rescisão"
              listItems={waiverLetterTypes}
            />
          )}

          <TmpTextarea
            id="description"
            handleChange={handleValueChange}
            label="Descrição"
            placeholder="Descreva aqui sua solicitação"
            helperText={'O chamado deve ser realizado individualmente, por cada colaborador em exceção'}
          />

          {values.reason === 'Remuneração' && (
            <InfoContainer>
              <InfoButton text={INFO_BUTTON_TEXT} />
            </InfoContainer>
          )}

          <br />

          <CollaboratorContainer>
            <TmpInput
              error={contributorError}
              id="registration"
              handleChange={handleValueChange}
              value={values.registration}
              label="*Matrícula do colaborador relacionado no chamado"
              placeholder="Ex: 11222"
              maxLength={50}
              helperText={
                !!contributorError ? contributorError : ''
              }
            />

            <TmpButton
              type="primary"
              size="sm"
              handleClick={handlerFindCollaborator}
              disabled={values.registration.length <= 3}
            >
              Buscar Colaborador
            </TmpButton>
          </CollaboratorContainer>

          {!!contributor && (
            <TmpInput
              disabled
              id="contributor"
              label="Colaborador"
              value={contributor.Name ?? contributor.MiddleName}
              helperText={hasError}
            />
          )}

          <Uploader onChange={handleUploadFiles} />

          <ButtonForm>
            <TmpButton
              type="primary"
              disabled={Object.keys(errorMessage).length > 0}
              size="sm"
              handleClick={handleSubmit}
            >
              Enviar
            </TmpButton>
            <TmpModal
              isOpened={isOpened}
              isDoubleAction={false}
              labelPrimary="Fechar"
              handleConfirm={(e) => handleClose()}
              handleCancel={(e) => setIsOpened(false)}
              handleClose={() => setIsOpened(false)}
            >
              <ContentModal>
                {requestStatus === 'processing' && (
                  <Loading />
                )}

                {requestStatus === 'success' && (
                  <AnimationIcon animationData={successAnimation} />
                )}

                {requestStatus === 'error' && (
                  <AnimationIcon animationData={errorAnimation} />
                )}
                <h1>{requestMessage}</h1>
                <h3>{integrationError}</h3>
              </ContentModal>
            </TmpModal>
          </ButtonForm>
        </Content>
      </Form>
      <Footer>
        <p>© {ano()} Hospital Sírio-Libanês</p>
      </Footer>
    </Container>
  );
};

export default RhForm;
